import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { Seo } from "../gatsby-components"
import { Layout, GridListBlackLink, Picture } from "../components"
import {
  FlexibleGridBlock,
  FlexibleGridBlockItem,
  FlexibleGridBlockStack,
  FlexibleGridBlockImage,
} from "../blocks"
import { layout } from "../temp-content/layout"

const IdeenPage = () => {
  return (
    <>
      <Seo title="Team" />
      <Layout {...layout}>
        <div className="grid gap-8 sm:gap-12">
          {/* First Row */}
          <FlexibleGridBlock
            cols={3}
            items={[
              <FlexibleGridBlockItem colSpan={2}>
                <FlexibleGridBlockImage
                  image={
                    <StaticImage
                      src="../images/team-temp/firstLeft.png"
                      alt="firstleft"
                    />
                  }
                />
              </FlexibleGridBlockItem>,
              <FlexibleGridBlockStack
                colSpan={1}
                items={[
                  <Picture
                    image={
                      <StaticImage
                        src="../images/team-temp/firstRightTop.png"
                        alt="firstRightTop"
                      />
                    }
                    content={{ headline: "Bella" }}
                    iconClassName="!mb-4 !mr-3"
                  />,
                  <Picture
                    image={
                      <StaticImage
                        // src="../images/team-temp/firstRightBottom.png"
                        src="../images/team-temp/fourthRightTop.png" // black
                        alt="Nadine Becker"
                      />
                    }
                    content={{
                      headline: "Nadine Becker",
                      description: "M.A.",
                    }}
                    iconClassName="!mb-4 !mr-3"
                  />,
                ]}
              />,
            ]}
            noPaddingTop
            noPaddingBottom
          />
          {/* Second Row */}
          <FlexibleGridBlock
            noPaddingTop
            noPaddingBottom
            items={[
              <FlexibleGridBlockItem>
                <FlexibleGridBlockImage
                  image={
                    <Picture
                      image={
                        <StaticImage
                          src="../images/team-temp/second.png"
                          alt="Sebastian Bochem &amp; Ingo Schmidt"
                        />
                      }
                      content={{
                        headline: "Sebastian Bochem & Ingo Schmidt",
                        description: "Architekten AKS / GF",
                      }}
                      iconClassName="!mb-4 !mr-3"
                    />
                  }
                />
              </FlexibleGridBlockItem>,
            ]}
          />
          {/* Third Row */}
          <FlexibleGridBlock
            cols={3}
            items={[
              <FlexibleGridBlockStack
                colSpan={1}
                items={[
                  <Picture
                    image={
                      <StaticImage
                        src="../images/team-temp/fourthRightTop.png" // black
                        alt="Anastasia König"
                      />
                    }
                    content={{
                      headline: "Anastasia König",
                      description: "Architektin AKS",
                    }}
                    iconClassName="!mb-4 !mr-3"
                  />,
                  <Picture
                    image={
                      <StaticImage
                        src="../images/team-temp/thirdLeftBottom.png"
                        alt="Matthias Hoffeld"
                      />
                    }
                    content={{
                      headline: "Matthias Hoffeld",
                      description: "Architekt AKS",
                    }}
                    iconClassName="!mb-4 !mr-3"
                  />,
                ]}
              />,
              <FlexibleGridBlockItem colSpan={2}>
                <FlexibleGridBlockImage
                  image={
                    <StaticImage
                      src="../images/team-temp/thirdRight.png"
                      alt="thirdRight"
                    />
                  }
                />
              </FlexibleGridBlockItem>,
            ]}
            noPaddingTop
            noPaddingBottom
          />
          {/* Fourth Row */}
          <FlexibleGridBlock
            cols={3}
            items={[
              <FlexibleGridBlockItem colSpan={2}>
                <FlexibleGridBlockImage
                  image={
                    <StaticImage
                      src="../images/team-temp/fourthLeft.png"
                      alt="fourthleft"
                    />
                  }
                />
              </FlexibleGridBlockItem>,
              <FlexibleGridBlockStack
                colSpan={1}
                items={[
                  <Picture
                    image={
                      <StaticImage
                        src="../images/team-temp/fourthRightTop.png" // black
                        alt="Pia Naudorf"
                      />
                    }
                    content={{
                      headline: "Pia Naudorf",
                      description: "Architektin AKS",
                    }}
                    iconClassName="!mb-4 !mr-3"
                  />,
                  <Picture
                    image={
                      <StaticImage
                        src="../images/team-temp/fourthRightBottom.png"
                        alt="Alexandra Engel"
                      />
                    }
                    content={{
                      headline: "Alexandra Engel",
                      description: "Assistenz",
                    }}
                    iconClassName="!mb-4 !mr-3"
                  />,
                ]}
              />,
            ]}
            noPaddingTop
            noPaddingBottom
          />
          {/* Fifth Row */}
          <FlexibleGridBlock
            noPaddingTop
            cols={3}
            items={[
              <FlexibleGridBlockItem colSpan={1}>
                <FlexibleGridBlockImage
                  image={
                    <Picture
                      image={
                        <StaticImage
                          src="../images/team-temp/fifthLeft.png"
                          alt="Daniela Kiefer"
                        />
                      }
                      content={{
                        headline: "Daniela Kiefer",
                        description: "Bauzeichnerin IHK",
                      }}
                      iconClassName="!mb-4 !mr-3"
                    />
                  }
                />
              </FlexibleGridBlockItem>,
              <FlexibleGridBlockItem colSpan={1}>
                <GridListBlackLink
                  className="!h-full !w-full"
                  link={
                    <a href="/bochem-schmidt_werde-teil-unseres-teams.pdf" target="_blank" download>
                      Werde
                      <br />
                      Teil unseres
                      <br />
                      Teams
                    </a>
                  }
                />
              </FlexibleGridBlockItem>,
            ]}
          />
        </div>
      </Layout>
    </>
  )
}

export default IdeenPage
